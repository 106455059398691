import React from 'react'
import {Link, graphql} from 'gatsby'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import BackgroundImage from 'gatsby-background-image'
import Wrapper from '../../components/wrapper'

import Layout from '../../containers/layout'
import SEO from '../../components/seo'

export const query = graphql`
  query CategoriesPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      innerImage {
      asset {
        fluid(maxHeight: 300) {
          ...GatsbySanityImageFluid_withWebp
        }
       }
      }
    }
    categories: allSanityCategory {
    nodes {
      id
      title
      slug {
        current
      }
      posts {
        _id
        slug {
          current
        }
        title
        mainImage {
          alt
          asset {
            fluid {
              src
            }
          }
        }
      }
    }
  }
  news: sanityCategory(title: {eq: "News"}) {
    posts {
      _id
      slug {
        current
      }
      title
      mainImage {
        alt
        asset {
          fluid {
            src
          }
        }
      }
    }
  }
  education: sanityCategory(title: {eq: "Education"}) {
    posts {
      _id
      slug {
        current
      }
      title
      mainImage {
        alt
        asset {
          fluid {
            src
          }
        }
      }
    }
  }
  poetry: sanityCategory(title: {eq: "Poetry"}) {
    posts {
      _id
      slug {
        current
      }
      title
      mainImage {
        alt
        asset {
          fluid {
            src
          }
        }
      }
    }
  }
  }
`

const CategoriesPage = props => {
  const {data, errors} = props
  const fullList = data.categories.nodes || {}
  const listPosts = fullList.posts
  {console.log(listPosts)}

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const site = (data || {}).site

  return (
    <Layout>
      <SEO title='Categories' />
      <BackgroundImage
        style={{
          backgroundPosition: 'top',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#ffffff',
          textShadow: '1px 2px 6px #000000',
          textAlign: 'center',
          height: 100
        }}
        fluid={site.innerImage.asset.fluid}
        backgroundColor={`#102514`}
      >
        <h2>Categories</h2>
      </BackgroundImage>
      <Wrapper>
        <div style={{
          marginTop: '3rem',
          textAlign: 'center'
        }}>
        {fullList && (
              <Container maxWidth='sm'>
                {fullList.map(list => (
                  <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center' key={list.id}>
                    <Link to={`/blog/categories/${list.slug.current}`}>{list.title}</Link>
                  </Box>
                  ))
                }
              </Container>)
          }
        
          <br /><br />
          <Link to='/'>Go Back to the Main page</Link>
        </div>
      </Wrapper>
    </Layout>
  )
}

export default CategoriesPage
